/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/dashboard',
      name: 'Dashboard',
      view: 'Dashboard'
    },
    {
      path: '/runner',
      name: 'Runner',
      view: 'Runner'
    },
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/users',
      name: 'User Management',
      view: 'accounts/Users'
    },
    {
      path: '/user-list',
      name: 'User Management',
      view: 'accounts/UsersList'
    },
    {
      path: '/game-settings',
      name: 'Game Settings',
      view: 'accounts/Settings'
    },
    {
      path: '/game-login',
      name: 'Log in',
      view: 'games/Login'
    },
    {
      path: '/game-dashboard',
      name: 'Game Dashboard',
      view: 'games/Dashboard'
    },
    {
      path: '/3d-game-setup',
      name: '3d Game',
      view: 'game-setup/3D'
    },
    {
      path: '/2d-game-setup',
      name: '2d Game',
      view: 'game-setup/2D'
    },
    {
      path: '/4d-game-setup',
      name: '4D Game',
      view: 'game-setup/4D'
    },
    {
      path: '/swer3-game-setup',
      name: 'SWER3 Game',
      view: 'game-setup/SWER3'
    },
    {
      path: '/swer2-game-setup',
      name: 'SWER2 Game',
      view: 'game-setup/SWER2'
    },
    {
      path: '/3dlocal-game-setup',
      name: '3D LOCAL Game',
      view: 'game-setup/3DLOCAL'
    },
    {
      path: '/2dlocal-game-setup',
      name: '2D LOCAL Game',
      view: 'game-setup/2DLOCAL'
    },
    {
      path: '/pick3-game-setup',
      name: 'PICK 3 Game',
      view: 'game-setup/PICK3'
    },
    {
      path: '/stlpares-game-setup',
      name: 'STL PARES',
      view: 'game-setup/STLPARES'
    },
    {
      path: '/stllocal-game-setup',
      name: 'STL LOCAL',
      view: 'game-setup/STLLOCAL'
    },
    {
      path: '/3d-game',
      name: '3D GAME',
      view: 'games/3D-game'
    },
    {
      path: '/2d-game',
      name: '2D GAME',
      view: 'games/2D-game'
    },
    {
      path: '/4d-game',
      name: '4D GAME',
      view: 'games/4D-game'
    },
    {
      path: '/swer3-game',
      name: 'SWER3 GAME',
      view: 'games/Swer3-game'
    },
    {
      path: '/swer2-game',
      name: 'SWER2 GAME',
      view: 'games/Swer2-game'
    },
    {
      path: '/3dlocal-game',
      name: '3D-LOCAL GAME',
      view: 'games/3DLOCAL-game'
    },
    {
      path: '/2dlocal-game',
      name: '2D-LOCAL GAME',
      view: 'games/2DLOCAL-game'
    },
    {
      path: '/pick3-game',
      name: 'PICK 3 GAME',
      view: 'games/PICK3-game'
    },
    {
      path: '/stlpares-game',
      name: 'STL PARES GAME',
      view: 'games/STLPARES-game'
    },
    {
      path: '/stllocal-game',
      name: 'STL LOCAL GAME',
      view: 'games/STLLOCAL-game'
    },
    {
      path: '/reports',
      name: 'Daily Reports',
      view: 'reports/Reports'
    },
    {
      path: '/cash-reports',
      name: 'Cash Reports',
      view: 'reports/Cash'
    },
    {
      path: '/key-in',
      name: 'Key In',
      view: 'reports/KeyIn'
    },
    {
      path: '/void-tickets',
      name: 'Void Tickets',
      view: 'reports/Voids'
    },
    {
      path: '/winning-tickets',
      name: 'Winning Tickets',
      view: 'reports/WinningTickets'
    }
  ]
  