<template>
    <v-dialog
    v-model="show"
    persistent
    width="300">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-ticket</v-icon><span class="va-underline">Ticket number</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                <v-text-field 
                type="number"
                    v-model="entry.ticket_id"
                    label="Enter ticket number"
                    dense
                    outlined
                    :rules="nameRules"
                    required
                />
                
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
               <v-btn small color="warning" @click="emitToParent('close')">
                  Not Now
              </v-btn>
              <v-spacer/>
              <v-btn small color="error" @click="validate()">
                  Void
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data () {
      return {
          valid: true,
          entry:{},
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(val) {
         if(val) {
             this.entry = {}
         }
     }
  },
  computed:{
   
  },
  methods: {
    validate () {
    if (this.$refs.form.validate()) {
        this.ConfirmVoid()
    }
    },
   emitToParent (action) {
      this.$emit('DialogEvent',action )
    },
    ConfirmVoid(){
        var ok = confirm("Void this ticket number?");
        if(ok) {
            this.Void()
        }
    },
    Void(){
        this.entry.ticket_id = this.$IsNum(this.entry.ticket_id) 
         this.$http.post("ticket/check_void",this.entry).then(response => {
              if(!response.data.status) {
                  alert(response.data.message)
              } else {
                  this.DoVoid()
              }
                 this.$emit('DialogEvent', "close")
          }).catch(e => {
            console.log(e.data)
          })
    },
    DoVoid(){
         this.entry.ticket_id = this.$IsNum(this.entry.ticket_id) 
        this.$http.post("ticket/void",this.entry).then(response => {
                alert(response.data.message)
          }).catch(e => {
            console.log(e.data)
          })
    }
    
  }
}
</script>
