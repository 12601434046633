import TableCSVExporter from '@/plugins/tabletocsv'
import {
    mapMutations
  } from 'vuex'
export const API_MIXINS = {
    methods: {
        ...mapMutations([
            'setAlert',
            'setAlertColor', 
            'setAlertText',
            'setGameSetting',
            'setOverlay',
            'setAllowStatBox',
            'setAllowSetUp',
            'setAdmin'
        ]),
        VA_ALERT(color, message) {
                this.setAlertText(message)
                this.setAlertColor(color)
                this.setAlert(true)
        },
        SET_PERMISSION(action){
          this.$session.get('role') == "Administrator"?this.setAdmin(true):this.setAdmin(false)
          if('game_set_up' == action) {
              this.$session.get('role') == "Administrator"?this.setAllowSetUp(true):this.setAllowSetUp(false)
          }
          if('game_stat_box'  == action) {
            this.$session.get('role') == "Administrator"?this.setAllowStatBox(true):this.setAllowStatBox(false)
         }
        },
        GET_GAME_SETTING(_code){
            this.setOverlay(true)
            this.$http.post("game/get", {code:_code}).then(response => {
             response.data.game != null?this.setGameSetting(response.data.game):this.setGameSetting({})
             this.setOverlay(false)
           }).catch(e => {
             console.log(e.data)
           })
       },
       DownloadToCsv (eleID, filename) {
        const dataTable = document.getElementById(eleID)
        const exporter = new TableCSVExporter(dataTable)
        const csvOutput = exporter.convertToCSV()
        const csvBlob = new Blob([csvOutput], { type: 'text/csv' })
        const blobUrl = URL.createObjectURL(csvBlob)
        const anchorElement = document.createElement('a')
  
        anchorElement.href = blobUrl
        anchorElement.download =  filename+ '.csv'
        anchorElement.click()
  
        setTimeout(() => {
          URL.revokeObjectURL(blobUrl)
        }, 500)
      }
    } ///eof methods
}