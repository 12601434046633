<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
            PER DRAW
            <v-spacer/>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTransactions()" />
                    </v-menu>
               </span>
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
             <p class="text-center">
                GAME GROSS PER DRAW REPORT<br />
Draw Period:  PER DRAW<br />
Date Covered: {{$moment(from_date).format('YYYY-MMM-DD')}} - {{$moment(to_date).format('YYYY-MMM-DD')}} 
            </p>
            <table class="va-table def-font" id="over-table">
                <tr>
                    <!-- <th>DRAW ID</th> -->
                    <th>GAME</th>
                    <!-- <th>STATUS</th> -->
                    <th>DRAW</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">UNIT HITS</th>
                    <th class="text-right">AMOUNT HITS</th>
                </tr>
                 <tr v-for="(item,index) in items" :key="index" >
                     <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                    <td class="border_bot">{{item.game}} </td>
                    <!-- <td class="border_bot">{{item.status}} </td> -->
                    <td class="border_bot">{{item.draw_time}}</td>
                    <td class="text-right border_bot">{{$money(item.gross)}}</td>
                    <td class="text-right border_bot">{{$money(item.amount_of_winning)}}</td>
                    <td class="text-right border_bot">{{$money(item.payout)}}</td>
                </tr>
                <tr>
                    
                     <th  class="text-right" style="background: #efefef;" colspan="2">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALHITS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                </tr>
               
            </table>
            <p class="mt-12">BY COMMISSION</p>
            <table class="va-table def-font" id="over-table2">
                <tr>
                    <th>DRAW</th>
                    <th>COMM</th>
                    <th class="text-right">GROSS</th>
                    <th class="text-right">AMOUNT HITS</th>
                </tr>
                 <tr v-for="(item,index) in commissiongroup" :key="index" >
                    <td class="border_bot">{{item.draw_date}} </td>
                    <td class="border_bot">{{item.commission}}%</td>
                    <td class="text-right border_bot">{{$money(item.gross)}}</td>
                    <td class="text-right border_bot">{{$money(item.win_amount)}}</td>
                </tr>
                <tr>
                    
                     <th  class="text-right" style="background: #efefef;"></th>
                    <th class="text-right"  style="background: #efefef;">TOTAL: </th>
                    <th class="text-right"  style="background: #efefef;">{{$money(COMTOTALGROSS)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(COMTOTALHITS)}}</th>
                </tr>
               
            </table>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        commissiongroup:[],
        items:[]
       
    }),
    computed:{
        COMMISSION_ITEMS() {
             var Bydraw = this.$groupBy('draw_date')
            return Bydraw(this.commissiongroup)
        },
        TOTALGROSS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        },
         TOTALHITS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.amount_of_winning),0)
            return total
        },
         TOTALPAYOUT() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.payout),0)
            return total
        },
        COMTOTALGROSS() {
            var total = this.commissiongroup.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        },
         COMTOTALHITS() {
            var total = this.commissiongroup.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
            return total
        },
    },
  methods: {
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "perdraw"
             this.$http.post("report/transactions",param).then(response => {
                response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
                this.getPerCom()
          }).catch(e => {
            console.log(e.data)
          })
        },
         getPerCom(){
            this.loading = true
            this.commissiongroup =[]
            var param = {}
            param.from_date = this.from_date
            param.to_date = this.to_date
            param.Method = "per_commission"
             this.$http.post("report/transactions",param).then(response => {
                response.data.items !=null?this.commissiongroup = response.data.items:this.commissiongroup =[]
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        dlToCsv(){
            this.DownloadToCsv("over-table", 'Per-draw-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
