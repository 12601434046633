<template>
    <v-dialog
    v-model="show"
    persistent
    width="670">
   
    <v-card color="light_gray">
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-gamepad-circle-up</v-icon><span class="va-underline">KEY-IN FORM</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="pa-4 def-font">
               <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                        
                        <v-col cols="12">
                             <v-text-field
                             type="number"
                                v-model="draw_id"
                                label="Enter Draw ID"
                                outlined
                                dense
                                :rules="nameRules"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <p>Draw: {{DRAW.date}} {{DRAW.time}}</p>
                        </v-col>
                       
                         <!-- 3D -->
                        <template>
                        <v-col cols="4">
                            <input 
                                type="number"
                                v-model="Result.num1"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 1"
                            />
                        </v-col>
                         <v-col cols="4">
                              <input 
                                type="number"
                                v-model="Result.num2"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 2"
                            />
                        </v-col>
                         <v-col cols="4">
                              <input 
                                type="number"
                                v-model="Result.num3"
                                :rules="nameRules"
                                required
                                class="res_input"
                                 placeholder="Num 3"
                            />
                        </v-col>
                        </template>
                    
                       
                    </v-row>
               </v-form>
               <v-divider/>
          </v-card-text>
          <v-card-actions>
              <v-btn x-large color="warning" @click="emitToParent('close')">
                 close
              </v-btn>
              <v-spacer/>
              <v-btn x-large  color="success" :loading="loading" @click="validate()">
                 KEY-IN
              </v-btn>
          </v-card-actions>
    </v-card>
     <va-confirm :show="confirm" :message="CONFIRM_MESSAGE" okbtn="Confirm" cancelbtn="Cancel" @ConfirmEvent="cdEvent"/>
  </v-dialog>
</template>

<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
export default {
    mixins: [API_MIXINS],
  props: {
      show:{
          type: Boolean,
          default: false
      },
      game3d: {
          type: Array,
            default:()=>{ return []}
      },
       game3dlocal: {
          type: Array,
          default:()=>{ return []}
      }
  },
  data () {
      return {
          loading: false,
          draw_id: '',
          confirm: false,
          Result:{},
          valid: true,
           nameRules: [
            v => !!v || '*Required'
        ],
      }
    },
  watch: {
     show(v) {
         if(v) {
             this.Result = {}
             this.draw_id = ''
         }
     }
  },
  computed:{
      DRAW(){
        var draw = this.game3d.reduce((res,item)=>{
            if(item.id==this.draw_id) {
                res=item
            }
            return res
        }, {})

        draw = this.game3dlocal.reduce((res,item)=>{
            if(item.id==this.draw_id) {
                res=item
            }
            return res
        }, draw)

        return draw
        },
      CONFIRM_MESSAGE() {
          var message = "Confirm result: " + this.Result.num1 +" - " + this.Result.num2+" - " + this.Result.num3
         
         return message
      }
  },
  methods: {
      cdEvent(data) {
          this.confirm = false
          if('ok' == data) {
              this.loading = true
              this.keyIn()
          }
      },
   emitToParent (action) {
      this.$emit('DialogEvent', {action:action})
    },
    validate(){
        if (this.$refs.form.validate()) {
           this.confirm =true
        }
    },
    
    keyIn(){
        var param = {}
        param.draw_id = this.$IsNum(this.draw_id)
        param.result = {
                num1: this.$IsNum(this.Result.num1),
                num2: this.$IsNum(this.Result.num2),
                num3: this.$IsNum(this.Result.num3)
            }
        
        console.log(param)
            this.$http.post("game/add_result", param).then(response => {
            response.data.status?this.VA_ALERT('success',response.data.message):this.VA_ALERT('error',response.data.message)
             this.loading = false
           this.$emit('DialogEvent', {action:'close'})
            }).catch(e => {
                console.log(e)
            })
            
    }
  }
}
</script>
