<template>
    <v-dialog
        v-model="show"
        fullscreen
        persistent
    >
   
    <v-card dense>
        <v-toolbar>
             <v-icon left color="secondary">mdi-map-marker-radius</v-icon> {{area.name}}
            <v-spacer/>
               <v-icon right color="primary" @click="emitToParent('close')">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
            <v-row no-gutters class="mt-4">
                <span style="width: 250px; margin: 3px">
                    <v-autocomplete
                        v-model="municipal"
                        label="Municipal"
                        :items="area.Areas"
                        item-text="name"
                        item-value="name"
                        @change="barangay='', account=0"
                        dense
                        filled
                        outlined
                    />
                </span>
                   <span style="width: 250px; margin: 3px">
                    <v-autocomplete
                        v-model="barangay"
                        label="Barangay"
                        :items="BARANGAYS"
                         @change="account=0"
                        dense
                        filled
                        outlined
                    />
                </span>
                 <span style="width: 250px; margin: 3px">
                    <v-autocomplete
                        v-model="account"
                        label="Agent"
                        :items="AGENTS"
                          item-text="fullname"
                        item-value="account"
                        dense
                        filled
                        outlined
                    />
                </span>
                <v-spacer/>
                <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
            </v-row>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            <table class="va-table def-font" id="acccount-table">
                <tr>
                     <td >Municipal: {{municipal}}</td>
                     <td colspan="5">Barangay: {{barangay}}</td>
                </tr>
                <tr>
                    <th>AGENT</th>
                    <th>GAME</th>
                    <th>DRAW</th>
                    <th>MUNICIPAL</th>
                    <th>BARANGAY</th>
                    <th class="text-right">GROSS</th>
                     <th class="text-right">WIN</th>
                     <th class="text-right">PAYOUT</th>
                </tr>
                 <tr v-for="(item,index) in FILTERITEMS" :key="index" >
                    <td class="border_bot">{{item.fullname}} </td>
                    <td class="border_bot">{{item.game}} </td>
                    <td class="border_bot">{{item.draw_date}}  {{item.draw_time}} </td>
                    <td class="border_bot">{{item.municipal}} </td>
                    <td class="border_bot">{{item.barangay}} </td>
                    <td class="text-right border_bot">{{$money(item.gross)}}</td>
                    <td class="text-right border_bot">{{$money(item.win)}}</td>
                    <td class="text-right border_bot">{{$money(item.payout)}}</td>
                </tr>
                <tr>
                     <th  class="text-right" style="background: #efefef;" colspan="5">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                      <th class="text-right"  style="background: #efefef;">{{$money(TOTALWIN)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                </tr>
            </table>
        
        </v-card-text>     
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      area:{
          type: Object,
          default: ()=>{}
      },
      daterange:{
          type: Object,
          default: ()=>{}
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        municipal:'',
        barangay:'',
        account: 0,
        items:[],
        areas:[]
    }),
    watch: {
        show(val) {
            if(val) {
                    this.municipal =''
                    this.barangay = ''
                    this.account=0
                    this.loading =true
                    this.items = []
                    setTimeout(()=>{ this.getTransactions()}, 800)
            }
        }
    },
    computed:{
         TOTALWIN() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.win),0)
            return total
        },
         TOTALPAYOUT() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.payout),0)
            return total
        },
        FILTERITEMS(){
            console.log(this.account)
            if(this.municipal == '' && this.barangay == '' && this.account==0) {
                return this.items
            }
                 var arr = this.items.reduce((res,item)=>{
                     if(this.municipal != '' && this.barangay == '') {
                        if(item.municipal ==  this.municipal) {
                            res.push(item)
                        }
                     } else if(this.municipal != '' && this.barangay != '' && this.account == 0) {
                        if(item.barangay ==  this.barangay) {
                            res.push(item)
                        }
                     } else if(this.account != 0) {
                        if(item.account ==  this.account) {
                                res.push(item)
                        }
                     }
                     return res
                 }
                 ,[])
         return arr
        },
        BARANGAYS(){
                var selected_areas = this.area.Areas != undefined?this.area.Areas:[]
             var arr = selected_areas.reduce((res,item)=>{
                       if(item.name ==  this.municipal) {
                           res = item.barangays
                        }
                     return res
                 },[])
         return arr
        },
        AGENTS(){
             var arr = this.items.reduce((res,item)=>{
                       if(item.barangay ==  this.barangay) {
                           res.push(item)
                        }
                     return res
                 },[])
            return arr
        },
        TOTALGROSS() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        }
    },
  methods: {
      emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.daterange.from_date
            param.to_date = this.daterange.to_date
            param.Method = "peraccount"
            param.account = this.area.id
             this.$http.post("report/transactions",param).then(response => {
                response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
                 this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        TotalGross(area){
            var total = this.items.reduce((res,item)=>{ 
                if(area == item.area_name) {
                        res+=this.$IsNum(item.gross)
                }
                return res
            }, 0)
            return total
        },
        dlToCsv(){
            this.DownloadToCsv("acccount-table", 'Per-account-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
