<template>
        <v-row>
                <v-col cols="6">
                    <p class="ma-1">OPEN DRAWS</p>
                    <v-data-table
                            class="va-table elevation-1"
                            :headers="headers"
                            :items="OPEN"
                            >
                                <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                            <v-icon v-if="item.status != 'Drawn'" x-small left color="warning" @click="emitToParent(item)">mdi-pencil</v-icon>
                            {{ item.id }}</td>
                            <td>{{ item.date | moment('ll')}} {{ item.time}} </td>
                                <td class="text-right">{{ item.payout_percent }}</td>
                                <td class="text-right"><v-chip small dark :color="$StatusColor(item.status)">{{ item.status}}</v-chip></td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </v-col>
                 <v-col cols="6">
                       <p class="ma-1">CLOSED DRAWS</p>
                    <v-data-table
                            class="va-table elevation-1"
                            :headers="headers"
                            :items="CLOSE"
                            >
                                <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                            <v-icon v-if="item.status != 'Drawn'" x-small left color="warning" @click="emitToParent(item)">mdi-pencil</v-icon>
                            {{ item.id }}</td>
                            <td>{{ item.date | moment('ll')}} {{ item.time}} </td>
                                <td class="text-right">{{ item.payout_percent }}</td>
                                <td class="text-right"><v-chip small dark :color="$StatusColor(item.status)">{{ item.status}}</v-chip></td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </v-col>
                 <v-col cols="6">
                      <p class="ma-1">DRAWS READY</p>
                    <v-data-table
                              class="va-table elevation-1"
                            :headers="headers"
                            :items="READY"
                            >
                                <template v-slot:body="{ items }">
                        <tbody>
                       <tr v-for="(item,index) in items" :key="index">
                            <td>
                            <v-icon v-if="item.status != 'Drawn'" x-small left color="warning" @click="emitToParent(item)">mdi-pencil</v-icon>
                            {{ item.id }}</td>
                            <td>{{ item.date | moment('ll')}} {{ item.time}} </td>
                                <td class="text-right">{{ item.payout_percent }}</td>
                                <td class="text-right"><v-chip small dark :color="$StatusColor(item.status)">{{ item.status}}</v-chip></td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </v-col>
                 <v-col cols="6">
                      <p class="ma-1">DRAWN</p>
                    <v-data-table
                             class="va-table elevation-1"
                            :headers="headers"
                            :items="DRAWN"
                            >
                                <template v-slot:body="{ items }">
                        <tbody>
                       <tr v-for="(item,index) in items" :key="index">
                            <td>
                            <v-icon x-small left color="warning" @click="emitToParent(item)">mdi-pencil</v-icon>
                            {{ item.id }}</td>
                            <td>{{ item.date | moment('ll')}} {{ item.time}} </td>
                                <td class="text-right">{{ item.payout_percent }}</td>
                                <td class="text-right"><v-chip small dark :color="$StatusColor(item.status)">{{ item.status}}</v-chip></td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                </v-col>
        </v-row>
</template>

<script>
export default {
    data: () => ({
        headers: [
            {text:'Draw ID', sortable: false},
            {text:'Draw Date', sortable: false},
            {text:'(%)Payout', align:'right', sortable: false},
            {text:'Status', align:'right', sortable: false},
        ]
    }),
  props: {
      data:{
          type: Array,
          default: ()=>[]
      }
  },
  computed:{
      READY(){
          var arr = this.data.reduce((res,item)=>{
                if(item.status == 'Ready') {
                    res.push(item)
                }
                return res
          },[])
          return arr
      },
      CLOSE(){
          var arr = this.data.reduce((res,item)=>{
                if(item.status == 'Close') {
                    res.push(item)
                }
                return res
          },[])
          return arr
      },
      OPEN(){
          var arr = this.data.reduce((res,item)=>{
                if(item.status == 'Open') {
                    res.push(item)
                }
                return res
          },[])
          return arr
      },
      DRAWN(){
          var arr = this.data.reduce((res,item)=>{
                if(item.status == 'Drawn') {
                    res.push(item)
                }
                return res
          },[])
          return arr
      }
  },
  methods: {
       emitToParent (item) {
            this.$emit('DrawEvent', item)
        }
  }
}
</script>