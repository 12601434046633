<template>
  <v-main>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
  </v-main>
</template>

<script>
export default {
  metaInfo () {
    return {
      title2: 'Admin',
      title: 'ADMIN-PLAYBET247'
    }
  }
}
</script>


<style>
#core-view {
 margin-bottom: 50px;
}
</style>
