<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
            Cash Reports
            <v-spacer/>
               <v-icon right color="primary" @click="emitToParent('close')">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text>
            
            <v-row no-gutters class="mt-4">
                <span style="width: 150px; margin:3px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 150px; margin:3px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTransactions()" />
                    </v-menu>
               </span>
                <v-spacer />
                 <span style="width: 150px; margin: 3px">
                    <v-autocomplete
                        v-model="area"
                        label="Area"
                        :items="areas"
                        item-text="name"
                        return-object
                        dense
                        filled
                        outlined
                    />
                </span>
                <span style="width: 150px; margin: 3px">
                    <v-autocomplete
                        v-model="municipal"
                        label="Municipal"
                        :items="MUNICIPALS"
                        item-text="name"
                        return-object
                        dense
                        filled
                        outlined
                    />
                </span>
                   <span style="width: 150px; margin: 3px">
                    <v-autocomplete
                        v-model="barangay"
                        label="Barangay"
                        :items="municipal.barangays != undefined?municipal.barangays:[]"
                        dense
                        filled
                        outlined
                    />
                </span>
                 <span style="width: 150px; margin: 3px">
                    <v-autocomplete
                        v-model="account"
                        label="Agent"
                        :items="AGENTS"
                          item-text="fullname"
                        item-value="account"
                        dense
                        filled
                        outlined
                    />
                </span>
            </v-row>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
            <table class="va-table def-font" id="acccount-table">
                <tr>
                    <th>AGENT</th>
                    <th>DRAW</th>
                    <th>LOCATION</th>
                    <th>TYPE</th>
                    <th class="text-right">GROSS</th>
                </tr>
                 <tr v-for="(item,index) in FILTERITEMS" :key="index" >
                    
                </tr>
                <tr>
                     <!-- <th  class="text-right" style="background: #efefef;" colspan="5">TOTAL:</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                      <th class="text-right"  style="background: #efefef;">{{$money(TOTALWIN)}}</th>
                    <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th> -->
                </tr>
            </table>
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        users:[],
        municipal:{},
        barangay:{},
        account: 0,
        items:[],
        area:{},
        areas:[]
    }),
    created() {
        setTimeout(()=>{ this.getUsers()}, 300)
         setTimeout(()=>{ this.getAreas()}, 600)
    },
    computed:{
         TOTALWIN() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.win),0)
            return total
        },
         TOTALPAYOUT() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.payout),0)
            return total
        },
        FILTERITEMS(){
            if(this.municipal == '' && this.barangay == '' && this.account==0) {
                return this.items
            }
                 var arr = this.items.reduce((res,item)=>{
                     if(this.municipal != '' && this.barangay == '') {
                        if(item.municipal ==  this.municipal) {
                            res.push(item)
                        }
                     } else if(this.municipal != '' && this.barangay != '') {
                            if(item.barangay ==  this.barangay) {
                            res.push(item)
                        }
                     } else if(this.account != 0) {
                            if(item.account ==  this.account) {
                            res.push(item)
                        }
                     }
                     return res
                 }
                 ,[])
         return arr
        },
        MUNICIPALS(){
            return this.area.Areas != undefined?this.area.Areas:[]
        },
        BARANGAYS(){
                var selected_areas = this.area.Areas != undefined?this.area.Areas:[]
             var arr = selected_areas.reduce((res,item)=>{
                       if(item.name ==  this.municipal) {
                           res = item.barangays
                        }
                     return res
                 },[])
         return arr
        },
        AGENTS(){
             var arr = this.users.reduce((res,item)=>{
                       if(item.barangay ==  this.barangay) {
                           res.push(item)
                        }
                     return res
                 },[])
            return arr
        },
        TOTALGROSS() {
            var total = this.FILTERITEMS.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            return total
        }
    },
  methods: {
        getUsers: function() { 
        this.$http.post("user/list", JSON.stringify({})).then(response => {
            response.data.data != null?this.users = response.data.data:this.users =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
        getAreas(){
                this.areas = []
            this.$http.get("area/list",).then(response => {
                response.data.areas != null?this.areas = response.data.areas:this.areas =[]
            // this.loading = false
            }).catch(e => {
                console.log(e.data)
            })
        },
        getTransactions(){
        //     this.loading = true
        //     this.items =[]
        //     var param = {}
        //     param.from_date = this.daterange.from_date
        //     param.to_date = this.daterange.to_date
        //     param.Method = "peraccount"
        //     param.account = this.area.id
        //      this.$http.post("report/transactions",param).then(response => {
        //         response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
        //          this.loading = false
        //   }).catch(e => {
        //     console.log(e.data)
        //   })
        },
        TotalGross(area){
            var total = this.items.reduce((res,item)=>{ 
                if(area == item.area_name) {
                        res+=this.$IsNum(item.gross)
                }
                return res
            }, 0)
            return total
        },
        dlToCsv(){
            this.DownloadToCsv("acccount-table", 'Per-account-' + this.from_date + '-to-' + this.to_date)
        }
  }
}
</script>
