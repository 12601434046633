<template>
    <v-navigation-drawer
      v-model="$store.state.drawer"
      absolute
      dark
      id="va-drawer"
      color="primary"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="$store.state.applogo"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>GAME</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      
      <v-list dense>
      <v-list-item-group color="fff">
          <v-list-item  @click="$router.push('dashboard').catch(err => {})">
          <v-list-item-icon>
            <v-icon small>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mid-font">DASHBOARD</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
       <template v-if="ALLOWED_SET_UP">
        <v-subheader class="mid-font">GAMES SET UP</v-subheader>
        <v-list-item
          v-for="(item, i) in games"
         :key="i + '-games'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader class="mid-font">Accounts</v-subheader>
        <v-list-item
          v-for="(item, i) in accounts"
         :key="i + '-accounts'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
       </template>
        <v-subheader class="mid-font">REPORTS</v-subheader>
        <v-list-item
          v-for="(item, i) in reports"
         :key="i + '-reports'"
          @click="$router.push(item.page).catch(err => {})"
        >
          <v-list-item-icon>
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" class="mid-font"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-item-group>
    </v-list>


    </v-navigation-drawer>

</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data () {
      return {
        item: 1,
      accounts: [
          { title: 'Users', icon: 'mdi-account-key', page:'/users'},
           { title: 'Game Settings', icon: 'mdi-cogs', page:'/game-settings'}
        ],
        games: [
          { title: '2D - STL', icon: 'mdi-numeric-2-box', page:'/2d-game-setup'},
          { title: '3D - NATIONAL', icon: 'mdi-video-3d', page:'/3d-game-setup'},
         // { title: '4D', icon: 'mdi-numeric-4-circle', page:'/4d-game-setup'},
         // { title: 'SWER3 - LOCAL', icon: 'mdi-numeric-3-circle', page:'/swer3-game-setup'},
        //  { title: 'SWER2', icon: 'mdi-numeric-2-circle', page:'/swer2-game-setup'},
         //  { title: '3D LOCAL', icon: 'mdi-numeric-3-circle', page:'/3dlocal-game-setup'},
         //  { title: '2D LOCAL', icon: 'mdi-numeric-2-circle', page:'/2dlocal-game-setup'},
          //  { title: 'PICK 3', icon: 'mdi-numeric-3-circle', page:'/pick3-game-setup'},
         //   { title: 'STL PARES', icon: 'mdi-billiards', page:'/stlpares-game-setup'},
          //  { title: 'STL LOCAL', icon: 'mdi-numeric-2-circle', page:'/stllocal-game-setup'}
        ],
         reports: [
          { title: 'Reports', icon: 'mdi-chart-tree', page:'/reports'},
          { title: 'Users List', icon: 'mdi-account-key', page:'/user-list'},
          { title: 'Winning Tickets', icon: 'mdi-ticket', page:'/winning-tickets'},
          { title: 'Voids', icon: 'mdi-cancel', page:'/void-tickets'},
         //{ title: 'key-in ', icon: 'mdi-keyboard', page:'/key-in'}
        ],
        mini: true,
      }
    },
    computed: {
      ALLOWED_SET_UP() {
           if(this.$session.get('role') == "Administrator") {
              return true
           }
           return false
      }
    },
    methods: {
      ...mapMutations(['setDrawer']),
    }
  }
</script>