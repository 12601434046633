<template>
  <v-app>
    <v-content :class="!IsLoggedIn?'loginbg':''">
      <core-toolbar  :class="windowClass" v-if="IsLoggedIn"/>
      <core-drawer v-if="IsLoggedIn"/>
      <core-view :class="windowClass"/>
         <core-footer/>
    </v-content>
  
       <v-snackbar
      v-model="showAlert"
      :color="alertColor"
      :timeout="2000"
       :top="'top'"
     >
      {{alertText }}
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import {
  mapMutations
} from 'vuex'

export default {
  name: 'App',
  
  data: () => ({
  }),
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  computed:{
      overlay() {
         return this.$store.state.overlay
      },
      showAlert: {
        get () {
          return this.$store.state.show_alert
          },
          set (val) {
            this.setAlert(val)
          }
      },
      alertText() {
        return this.$store.state.alert_text
      },
      alertColor(){
          return this.$store.state.alert_color
      },
      windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      IsLoggedIn(){
         return this.$store.state.is_logged
      },
      FORM() {
        return this.$store.state.show_booking
      }
  },
  methods: {
    ...mapMutations(['setDrawer', 'setResponsive', 'setAlert', 'setShowBooking']),
    reserveEVent() {
      this.setShowBooking(false)
    },
      onResponsiveInverted() {
          if (window.innerWidth < 991) {
           this.setResponsive(true)
          } else {
            this.setDrawer(true)
            this.setResponsive(false)
          }
    },
  }

};
</script>
<style>
@import '~vue-context/dist/css/vue-context.css';
/* .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 36px !important;
} */
.v-input--dense .v-label {
   font-size: .91em !important;
}
  #va-drawer, #va-toolbar {
    text-transform: uppercase !important;
  }
  
  .va-not-reponsive {
    padding-left: 255px;
  }
  .va-table {
    width: 100%;
    text-align:  left;
  }
  .va-table tbody td {
    font-size: .8em !important;
  }
  @media print {
    
    .va-table  thead td {
    font-size: .5em !important;
  }
   .va-table  tbody td {
    font-size: .6em !important;
    height: 10px !important;
  }
   .va-table  tfoot td {
    font-size: .5em !important;
  }
}
  .va-underline {
    border-bottom: 1px solid #F96332;
  }
  .v-select__slot, .v-text-field__slot {
      font-size: .75em !important;
  }
  .v-btn__content {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .va-right input{
    text-align: right;
  }

/* Drawer */
#va-drawer .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
#va-drawer .v-expansion-panels .v-expansion-panel {
  background: none !important;
  /* border-radius: 0 !important;
  -webkit-box-shadow:none !important; 
   box-shadow: none !important;  */
}
#va-drawer .v-expansion-panel::before {
  -webkit-box-shadow:none !important; 
   box-shadow: none !important;

}
.va-tab {
  display: block;
  text-align: left;
  width: 100%;
}
  .loginbg {
     /* background-image: url('assets/bg.png');
     background-size: 100% 100%; */
  }
  #loginbtn{
    background: rgb(241,241,241);
background: radial-gradient(circle, rgba(241,241,241,1) 0%, rgba(221,221,221,1) 100%);
    color: #3F96B9 !important;
  }

  .small-font {
    font-size:  .6em !important;
  }
  .md-font {
     font-size:  .7em !important;
  }
  .def-font {
     font-size:  .9em !important;
  }
  .color-light {
    color: #b5b5b5;
  } 
 #roomprop .v-expansion-panel-content__wrap {
   padding: 0 !important;
  }

.border_bot {
  border-bottom: 1px solid #eee;
}

  .theme--light.v-input--is-disabled input {
    color: #000 !important;
  }
  .light_text {
    color: #ddd;
  }
  .shadow-text {
     text-shadow: 1px 1px #555;
  }
  .print_table {
    width: 100%;
    font-size: .8em;
    text-align: left;
  }
   .print_table  th {
     font-weight: normal;
   }
    .print_table  th,  .print_table  td {
      padding: 2px;
      border-bottom: 1px solid #c5c5c5;
    }
    .text-secondary {
  color: #19365A;
}
.text-success {
  color: #5cb860;
}
.text-error {
  color: #f55a4e;
}
.text-primary {
  color: #68BE9F;
}
.text-warning {
  color: #ffa21a;
}
.text-info {
  color: #00d3ee;
}
.text-accent {
  color: #82B1FF;
}
.text-gray {
  color: #353535;
}
.res_input {
  border: 2px solid #3844FF;
  text-align: center;
  font-size: 2.5em;
  padding: 10px;
  margin: 0 auto;
  width: 190px;
  font-weight: bold;
  color: #212121;
}
</style>