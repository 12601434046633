<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="920">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} User</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="6">
                  
               <v-text-field
                 v-model="user.fullname"
                  label="Full Name *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="user.username"
                  label="Username *"
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                  v-model="user.password"
                  v-if="state=='new'" 
                  label="Password *"
                  :rules="textRules"
                required
                 outlined
                  dense
                ></v-text-field>
              <v-text-field
                  v-model="user.contact_no"
                   outlined
                  dense
                  label="Contact No. "
                ></v-text-field>
                <v-text-field
                  v-model="user.email"
                   outlined
                  dense
                  label="Email"
                ></v-text-field>
    <v-text-field
                  v-model="user.address"
                   outlined
                  dense
                  label="Address "
                ></v-text-field>
                 <v-text-field
                      v-model="newpassword"
                      outlined
                    dense
                      name="input-10-1"
                      label="Change Password"
                      hint="At least 8 characters"
                      counter
                      v-if="show1==true"
                    ></v-text-field>
                   <v-btn   v-if="state!='new'" @click="show1=true" color="darken-1" text small><v-icon left small>mdi-key-variant</v-icon> Change Password</v-btn>
                </v-col>
                <v-col cols="6">
                   <v-autocomplete
                  v-model="user.role"
                   :rules="textRules"
                required
                  :items="roles"
                  outlined
                    dense
                  label="User Role"
                  ></v-autocomplete>   
                 <v-row dense v-if="user.role == 'Agent'" no-gutters>
                       <v-autocomplete
                        v-model="user.coordinator"
                        outlined
                          dense
                        :items="COORDINATORS"
                        item-text="fullname"
                        item-value="id"
                        label="Coordinator"
                        ></v-autocomplete>
                         <v-autocomplete
                          v-model="user.assigned_collector"
                          outlined
                            dense
                            class="ml-1"
                          :items="COLLECTORS"
                          item-text="fullname"
                          item-value="id"
                          label="Assigned Collector"
                          ></v-autocomplete> 
                 </v-row>
                  <v-text-field
                  v-model="user.machine_id"
                   outlined
                  dense
                  label="Unit Serial"
                  append-icon="mdi-barcode"
                ></v-text-field>
                  <v-autocomplete
                  v-model="user.area"
                  outlined
                    dense
                  :items="areas"
                  item-text="name"
                  item-value="id"
                  label="Cluster name"
                  ></v-autocomplete> 
                  {{user.Barangay}}
                  <v-autocomplete
                  v-model="user.barangay"
                  outlined
                    dense
                  :items="SELECTED_AREAS"
                  label="Barangay"
                  ></v-autocomplete> 
                  
                      <v-text-field
                    v-model="user.commission"
                    outlined
                    dense
                    label="Commision (%)"
                  ></v-text-field>
                   
                </v-col>
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    user: {
        type: Object,
        default: ()=>{ return {}}
    },
    users: {
        type: Array,
        default: ()=>[]
    },
    areas: {
      type:Array,
      default: ()=>{[]}
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      show1: false,
       roles: [ 'Administrator', 'Operator', 'Coordinator', 'Individual', 'Agent', 'Collector'],
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
      COORDINATORS(){
        var arr = this.users.reduce((res, item)=>{
         // console.log(item)
          if(item.role== 'Coordinator') {
           res.push(item)
          }
          return res
        }, [])
       // console.log(Barangays)
        return arr
      },
      COLLECTORS(){
        var arr = this.users.reduce((res, item)=>{
         // console.log(item)
          if(item.role== 'Collector') {
           res.push(item)
          }
          return res
        }, [])
       // console.log(Barangays)
        return arr
      },
      SELECTED_AREAS(){
        var Barangays = this.areas.reduce((res, item)=>{
         // console.log(item)
          if(item.id== this.user.area) {
            for(var i=0; i<item.areas.length;i++) {
              //console.log(item.areas[i])
                res.push(item.areas[i].name)
            }
          }
          return res
        }, [])
       // console.log(Barangays)
        return Barangays
      },
      SELECTED_MUN() {
        if(this.SELECTED_AREAS.Areas == undefined) {
          return {}
        }
           var municipal = this.SELECTED_AREAS.Areas.reduce((res, item)=>{
          if(item.name== this.user.municipal) {
            res = item
          }
          return res
        }, {})
        return municipal
      }
  },
  watch: {
    show () {
      if (this.show == false) {
        this.newpassword = ''
        this.show1=false
      } else {
        this.user.password =''
      }
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
          this.show1 = false
        }
      },
    emitToParent (action) {
      if(this.show1) {
        this.user.password = this.newpassword
      }
      console.log(this.user)
      this.$emit('DialogEvent', {action: action, user:this.user})
    }
  }
}
</script>
