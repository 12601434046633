<template>
    <div v-if="show">
   
    <v-card dense>
        <v-toolbar>
             RIDER  DAILY SUMMARY
            <v-spacer/>
            <span style="width: 270px; margin-top: 24px; margin-right: 7px;"> 
                    <v-autocomplete
                        :items="COLLECTORS"
                        label="Select Rider"
                        item-text="fullname"
                          v-model="coordinator"
                        return-object
                       @click="items=[], from_date = ''"
                         dense
                         outlined
                    />
               </span>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Select Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false, getTransactions()" />
                    </v-menu>
               </span>
                
               <v-icon right color="primary" @click="dlToCsv()">mdi-download</v-icon>
        </v-toolbar>
        <v-card-text>
             <v-progress-linear
                v-if="loading"
                indeterminate
                color="teal"
                ></v-progress-linear>
             <p class="text-center">
               RIDER  DAILY SUMMARY<br />
                Date Covered: {{$moment(from_date).format('YYYY-MMM-DD')}} 
            </p>
            <table class="print_table" id="daily_coordinator_summary">
                <tr>
                    <th colspan="8">RIDER: {{coordinator.fullname}}</th>
                   
                </tr>
                
                <tr>
                    <th class="text-left">No.</th>
                    <th class="text-left">USER CODE</th>
                    <th class="text-left">COORDINATOR</th>
                    <th class="text-left">GROSS</th>
                    <th class="text-left">COMM.</th>
                    <th class="text-left">NET</th>
                     <th class="text-left">HITS</th>
                </tr>
                <tr>
                    <th></th>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                    <th class="text-left">{{$money(GROSS)}}</th>
                    <th class="text-left">{{$money(COMMISSION)}}</th>
                    <th class="text-left">{{$money(GROSS - COMMISSION)}}</th>
                     <th class="text-left">{{$money(TOTALHITS)}}</th>
                </tr>
                <template v-for="(coor, dex) in COORSUMMARY">
                    <tr v-for="(item,index) in coor" :key="index">
                          <td class="text-left">{{index+1}}</td>
                        <td class="text-left">{{item.username}}</td>
                        <td class="text-left">{{item.coordinator_name}}</td>
                        <td class="text-left">{{$money(item.gross)}}</td>
                        <td class="text-left">{{$money(item.gross * (item.commission/100))}}</td>
                        <td class="text-left">{{$money(item.gross - (item.gross * (item.commission/100)) )}}</td>
                        <td class="text-left">{{$money(item.win_amount)}}</td>
                    </tr>
                    <tr :key="dex + '-c'">
                        <th class="text-left"></th>
                        <th class="text-left"></th>
                        <th class="text-right">TOTAL:</th>
                        <th class="text-left">{{$money(coortotal('gross', coor))}}</th>
                        <th class="text-left">{{$money(coortotal('commission', coor))}}</th>
                        <th class="text-left">{{$money((coortotal('gross', coor) - coortotal('commission', coor)))}}</th>
                        <th class="text-left">{{$money(coortotal('hits', coor))}}</th>
                    </tr> 
                    <tr :key="dex + '-d'">
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                    </tr> 
                </template>
                
                   <tr>
                   <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                        <th class="text-left" style="padding: 20px;"></th>
                </tr>
                <tr>
                    <th></th>
                    <th class="text-left"></th>
                    <th class="text-right">OVERALL TOTAL:</th>
                    <th class="text-left">{{$money(GROSS)}}</th>
                    <th class="text-left">{{$money(COMMISSION)}}</th>
                    <th class="text-left">{{$money(GROSS - COMMISSION)}}</th>
                     <th class="text-left">{{$money(TOTALHITS)}}</th>
                </tr>
            </table>
           
        </v-card-text>     
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
      show:{
          type: Boolean,
          default: false
      }
  },
  data: () => ({
        loading: false,
        from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
        items:[],
        coordinators:[],
        coordinator:{}
       
    }),
    watch: {
        show(v){
            if(v) {
                 this.getCoordinators()
            }
        }
    },
    computed:{
        COMMISSION() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross) * (this.$IsNum(item.commission)/100),0)
            return total
        },
        GROSS() {
                var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
                return total
        },
         TOTALNET() {
           // var total = this.items.reduce((res,item)=>res += this.GROSS - this.COMMISSION,0)
            return this.GROSS - this.COMMISSION
        },
         TOTALHITS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
                return total
        },
         CLAIMEDHITS() {
            var total = this.items.reduce((res,item)=>res += this.$IsNum(item.claimed_amount),0)
                return total
        },
         UNCLAIMEDHITS() {
            
                return this.TOTALHITS - this.CLAIMEDHITS
        },
        COORSUMMARY() {
            var byAccounts = this.$groupBy('coordinator_name')
            return byAccounts(this.items)
        },
         COLLECTORS() {
            var collectors = this.coordinators.reduce((res,item)=>{
                if(item.role == 'Collector') {
                    res.push(item)
                }
                return res
            },[])
            return collectors
        }
    },
  methods: {
      getCoordinators(){
            this.loading = true
            this.coodinators =[]
            this.$http.post("user/list", JSON.stringify({})).then(response => {
            response.data.data != null?this.coordinators = response.data.data:this.coordinators =[]
             this.loading = false
        }).catch(e => {
        console.log(e.data)
        })
        },
        coortotal(key, data){
            var total = 0
            if(key == 'gross') {
                  total = data.reduce((res,item)=>res += this.$IsNum(item.gross),0)
            } else if(key=='commission') {
                 total = data.reduce((res,item)=>res += this.$IsNum(item.gross) * (this.$IsNum(item.commission)/100),0)
            } else if(key=='net') {
                 total = data.reduce((res,item)=>res += this.$IsNum(item.gross) * (this.$IsNum(item.commission)/100),0)
            } else if(key=='hits') {
                 total = data.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
            } else if(key=='claimed') {
                 total = data.reduce((res,item)=>res += this.$IsNum(item.claimed_amount),0)
            }
            return total

        },
        getTransactions(){
            this.loading = true
            this.items =[]
            var param = {}
            param.from_date = this.from_date
            param.account = this.coordinator.id
            param.Method = "daily_collector_summary"
             this.$http.post("report/transactions",param).then(response => {
                response.data.items !=null?this.items = response.data.items:this.items =[]
                console.log(response.data.items)
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
        },
        dlToCsv(){
            this.DownloadToCsv("daily_coordinator_summary", 'DAILY-RIDER-SUMMARY-' + this.from_date)
        }
  }
}
</script>
