import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#79A12E',//'#F96332', //'#318CE7',
            secondary: '#004B71',
            tertiary: '#495057',
            accent: '#82B1FF',
            error: '#f55a4e',
            info: '#00d3ee',
            success: '#5cb860',
            warning: '#ffa21a',
            light_gray:"#f5f5f5",
            drark_gray:"#252429"
          },
        },
      }
});
