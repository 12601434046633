import Vue from 'vue'
// Lib imports
import axios from 'axios'
var production = true
Vue.prototype.$app_mode = "production"
var SERVER = "http://localhost:9001"
if(production) {
  if(Vue.prototype.$app_mode == "production")
       SERVER = "https://serve-cebu.mastersgame.net"
  else if(Vue.prototype.$app_mode == "local")
        SERVER = "http://192.168.100.11:7888"
}
Vue.prototype.$http = axios.create({
  baseURL: SERVER + '/api/'
})

Vue.prototype.$fileserver = SERVER + '/public/'